//
// CATEGORY MENU
//
@import "../../../scss/foundation/functions";
@import "../../../scss/base_settings";
@import "../../../scss/responsive-base/mixins";
@import "cat-nav-mobile-settings";

@media #{$medium-down} {
  //
  // SMALL-MEDIUM CATEGORY TREE
  //
  #cat-nav {

    .nav-bar {
      background: $js-cat-nav-mobile-background-color;
      display: block !important;
      position: absolute;
      transition: left 0.3s ease-in, opacity 0.3s ease-in;
      left: -100%;
      z-index: 12;
      width: 85%;
      margin: 0 0 0 $js-cat-nav-mobile-left;
      max-width: 24rem;
      border: $js-cat-nav-mobile-border;
      padding: $js-cat-nav-mobile-padding;
    }

    li, ul, a {
      height: auto;
      width: auto;
      float: none;
      margin: 0;
    }

    a {
      white-space: nowrap;
    }
    //
    // LVL 1
    //
    ul.lv1 {
      height: auto;
      width: auto;
      float: none;

      li.lv1 {
        background: $js-cat-nav-mobile-lv1-item-background-color;
        padding: $js-cat-nav-mobile-lv1-item-padding;
        margin: $js-cat-nav-mobile-lv1-item-margin;
        border-top: $js-cat-nav-mobile-lv1-item-border-top;
        border-right: $js-cat-nav-mobile-lv1-item-border-right;
        border-bottom: $js-cat-nav-mobile-lv1-item-border-bottom;
        border-left: $js-cat-nav-mobile-lv1-item-border-left;
        display: block;
        line-height: 0;
        position: relative;
        height: auto;
        width: auto;
        float: none;
        max-width: 30rem;

        & + .lv1 {
          border-top: $js-cat-nav-mobile-lv1-item-separator-border;
        }

        &.has-subcategories:before {
          content: $js-cat-nav-mobile-lv1-subcat-indicator-content;
          font-family: fontawesome;
          position: absolute;
          right: $js-cat-nav-mobile-lv1-subcat-indicator-right;
          top: $js-cat-nav-mobile-lv1-subcat-indicator-top;
          font-size: $js-cat-nav-mobile-lv1-subcat-indicator-font-size;
          color: $js-cat-nav-mobile-lv1-subcat-indicator-color;
          display: block;
          z-index: 2;
          line-height: 1;
        }

        &.open:before {
          content: $js-cat-nav-mobile-lv1-subcat-indicator-open-content;
        }

        > a {
          display: block;
          padding: $js-cat-nav-mobile-lv1-link-padding;
          margin: $js-cat-nav-mobile-lv1-link-margin;
          background: none !important;
          border: none;
          font-weight: $js-cat-nav-mobile-lv1-link-font-weight;
          font-family: $js-cat-nav-mobile-lv1-link-font-family;
          font-size: $js-cat-nav-mobile-lv1-link-font-size;
          line-height: $js-cat-nav-mobile-lv1-link-line-height;
          color: $js-cat-nav-mobile-lv1-link-color !important;
          text-decoration: $js-cat-nav-mobile-lv1-link-text-decoration;
          height: auto;
          width: auto;
          float: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    //
    // LVL 2
    //
    ul.lv2 {
      transition: 0.3s;
      box-shadow: none;
      display: block;
      height: auto;
      width: auto;
      float: none;
      border: none;
      position: static;
      padding: $js-cat-nav-mobile-lv2-container-padding;
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
      line-height: 0;

      .positioner {
        display: block !important;
        position: static !important;
      }

      .see-all {
        display: none !important;
      }

      li.lv2 {
        background: $js-cat-nav-mobile-lv2-item-background-color;
        padding: $js-cat-nav-mobile-lv2-item-padding;
        padding-top: 0;
        padding-bottom: 0;
        margin: $js-cat-nav-mobile-lv2-item-margin;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        margin: 0;
        border: 0;
        display: block;
        height: auto;
        width: auto;
        float: none;
        line-height: 0;
        max-height: 0;
        transition: 0.3s;
        max-width: 0;
        position: relative;
        overflow: hidden;

        &.has-subcategories:before {
          content: $js-cat-nav-mobile-lv2-subcat-indicator-content;
          font-family: fontawesome;
          position: absolute;
          right: $js-cat-nav-mobile-lv2-subcat-indicator-right;
          top: $js-cat-nav-mobile-lv2-subcat-indicator-top;
          font-size: $js-cat-nav-mobile-lv2-subcat-indicator-font-size;
          color: $js-cat-nav-mobile-lv2-subcat-indicator-color;
          display: block;
          z-index: 2;
          line-height: 1;
          opacity: 0;
        }

        &.open:before {
          content: $js-cat-nav-mobile-lv2-subcat-indicator-open-content;
        }

        > a {
          display: block;
          padding: $js-cat-nav-mobile-lv2-link-padding;
          padding-top: 0;
          padding-bottom: 0;
          margin: $js-cat-nav-mobile-lv2-link-margin;
          margin-top: 0;
          margin-bottom: 0;
          background: none !important;
          max-height: 0;
          box-sizing: content-box;
          transition: 0.3s;
          overflow: hidden;
          float: none;
          height: auto;
          width: auto;
          float: none;
          font-weight: $js-cat-nav-mobile-lv2-link-font-weight;
          font-family: $js-cat-nav-mobile-lv2-link-font-family;
          font-size: $js-cat-nav-mobile-lv2-link-font-size;
          line-height: $js-cat-nav-mobile-lv2-link-line-height;
          color: $js-cat-nav-mobile-lv2-link-color !important;
          text-decoration: $js-cat-nav-mobile-lv2-link-text-decoration;

          &:after {
            display: none !important;
          }
        }
      }
    }

    li.lv1.open ul.lv2 {
      padding: $js-cat-nav-mobile-lv2-container-padding;

      > li.lv2 {
        padding: $js-cat-nav-mobile-lv2-item-padding;
        margin: $js-cat-nav-mobile-lv2-item-margin;
        border-top: $js-cat-nav-mobile-lv2-item-border-top;
        border-right: $js-cat-nav-mobile-lv2-item-border-right;
        border-bottom: $js-cat-nav-mobile-lv2-item-border-bottom;
        border-left: $js-cat-nav-mobile-lv2-item-border-left;
        max-height: $js-cat-nav-mobile-open-max-height;
        max-width: 30rem;

        &.has-subcategories:before {
          transition: opacity 0.1s 0.3s;
          opacity: 1;
        }

        > a {
          padding: $js-cat-nav-mobile-lv2-link-padding;
          margin: $js-cat-nav-mobile-lv2-link-margin;
          max-height: 2.4em;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    //
    // LVL 3
    //
    ul.lv3 {
      transition: 0.3s;
      box-shadow: none;
      display: block;
      height: auto;
      width: auto;
      float: none;
      border: none;
      position: static;
      padding: $js-cat-nav-mobile-lv3-container-padding;
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
      line-height: 19px;
      position: static !important;

      .positioner {
        display: block !important;
        position: static !important;
      }

      li.lv3 {
        background: $js-cat-nav-mobile-lv3-item-background-color;
        padding: $js-cat-nav-mobile-lv3-item-padding;
        padding-top: 0;
        padding-bottom: 0;
        margin: $js-cat-nav-mobile-lv3-item-margin;
        margin-top: 0;
        margin-bottom: 0;
        //padding: 0;
        //margin: 0; //TODO what is this?
        border: 0;
        display: block;
        height: auto;
        width: auto;
        float: none;
        line-height: 0;
        max-height: 0;
        transition: 0.3s;
        max-width: 0;
        position: relative;
        overflow: hidden;

        &.has-subcategories:before {
          content: $js-cat-nav-mobile-lv3-subcat-indicator-content;
          font-family: "FontAwesome";
          position: absolute;
          right: $js-cat-nav-mobile-lv3-subcat-indicator-right;
          top: $js-cat-nav-mobile-lv3-subcat-indicator-top;
          font-size: $js-cat-nav-mobile-lv3-subcat-indicator-font-size;
          color: $js-cat-nav-mobile-lv3-subcat-indicator-color;
          display: block;
          z-index: 2;
          line-height: 1;
          opacity: 0;
        }

        &.open:before {
          content: $js-cat-nav-mobile-lv3-subcat-indicator-open-content;
        }

        > a {
          display: block;
          box-sizing: content-box;
          padding: $js-cat-nav-mobile-lv3-link-padding;
          padding-top: 0;
          padding-bottom: 0;
          margin: $js-cat-nav-mobile-lv3-link-margin;
          margin-top: 0;
          margin-bottom: 0;
          background: none !important;
          max-height: 0;
          transition: 0.3s;
          overflow: hidden;
          float: none;
          height: auto;
          width: auto;
          float: none;
          font-weight: $js-cat-nav-mobile-lv3-link-font-weight;
          font-family: $js-cat-nav-mobile-lv3-link-font-family;
          font-size: $js-cat-nav-mobile-lv3-link-font-size;
          line-height: $js-cat-nav-mobile-lv3-link-line-height;
          color: $js-cat-nav-mobile-lv3-link-color !important;
          text-decoration: $js-cat-nav-mobile-lv3-link-text-decoration;
        }
      }
    }

    li.lv2.open ul.lv3 {
      padding: $js-cat-nav-mobile-lv3-container-padding;

      > li.lv3 {
        padding: $js-cat-nav-mobile-lv3-item-padding;
        margin: $js-cat-nav-mobile-lv3-item-margin;
        border-top: $js-cat-nav-mobile-lv3-item-border-top;
        border-right: $js-cat-nav-mobile-lv3-item-border-right;
        border-bottom: $js-cat-nav-mobile-lv3-item-border-bottom;
        border-left: $js-cat-nav-mobile-lv3-item-border-left;
        max-height: $js-cat-nav-mobile-open-max-height;
        max-width: 30rem;

        &.has-subcategories:before {
          transition: opacity 0.1s 0.3s;
          opacity: 1;
        }

        > a {
          padding: $js-cat-nav-mobile-lv3-link-padding;
          margin: $js-cat-nav-mobile-lv3-link-margin;
          max-height: 2.4em;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    //
    // LVL 4
    //
    ul.lv4 {
      background: $js-cat-nav-mobile-lv4-item-background-color;
      transition: 0.3s;
      box-shadow: none;
      display: block;
      height: auto;
      width: auto;
      float: none;
      border: none;
      position: static;
      padding: $js-cat-nav-mobile-lv4-container-padding;
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
      line-height: 0;

      .positioner {
        display: block !important;
        position: static !important;
      }

      li.lv4 {
        background: $js-cat-nav-mobile-lv4-item-background-color;
        padding: $js-cat-nav-mobile-lv4-item-padding;
        padding-top: 0;
        padding-bottom: 0;
        margin: $js-cat-nav-mobile-lv4-item-margin;
        margin-top: 0;
        margin-bottom: 0;
        border: 0;
        display: block;
        height: auto;
        width: auto;
        float: none;
        line-height: 0;
        max-height: 0;
        transition: 0.3s;
        position: relative;
        overflow: hidden;

        > a {
          display: block;
          box-sizing: content-box;
          padding: $js-cat-nav-mobile-lv4-link-padding;
          padding-top: 0;
          padding-bottom: 0;
          margin: $js-cat-nav-mobile-lv4-link-margin;
          margin-top: 0;
          margin-bottom: 0;
          background: none !important;
          max-height: 0;
          transition: 0.3s;
          overflow: hidden;
          float: none;
          height: auto;
          width: auto;
          float: none;
          font-weight: $js-cat-nav-mobile-lv4-link-font-weight;
          font-family: $js-cat-nav-mobile-lv4-link-font-family;
          font-size: $js-cat-nav-mobile-lv4-link-font-size;
          line-height: $js-cat-nav-mobile-lv4-link-line-height;
          color: $js-cat-nav-mobile-lv4-link-color !important;
          text-decoration: $js-cat-nav-mobile-lv4-link-text-decoration;
        }
      }

      li.lv4.has-subcategories:before {
        display: none;
      }
    }

    li.lv3.open ul.lv4 {
      padding: $js-cat-nav-mobile-lv4-container-padding;

      > li.lv4 {
        padding: $js-cat-nav-mobile-lv4-item-padding;
        margin: $js-cat-nav-mobile-lv4-item-margin;
        border-top: $js-cat-nav-mobile-lv4-item-border-top;
        border-right: $js-cat-nav-mobile-lv4-item-border-right;
        border-bottom: $js-cat-nav-mobile-lv4-item-border-bottom;
        border-left: $js-cat-nav-mobile-lv4-item-border-left;
        max-height: $js-cat-nav-mobile-open-max-height;
        max-width: 30rem;

        > a {
          padding: $js-cat-nav-mobile-lv4-link-padding;
          margin: $js-cat-nav-mobile-lv4-link-margin;
          max-height: 2.4em;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  #menu-overlay {
    opacity: 0;
    transition: opacity 0.3s ease-out, z-index 0s 0.3s;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background: #000 none repeat scroll 0% 0%;
    z-index: -5;
  }

  //
  // MENU OPEN
  //
  .menu-open #menu-overlay {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.5;
    z-index: 9;
    display: block;
  }

  .menu-open #cat-nav .nav-bar {
    opacity: 1;
    box-shadow: $js-cat-nav-mobile-box-shadow;
    left: 0;
    transition: 0.3s ease-out;
    a {
      white-space: normal !important;
      overflow: hidden;
      display: block;
    }
  }


  //
  //  VAT / Currency / Culture selector in mobile cat nav
  //
  #cat-nav {
    .vat-selector-wrapper {
      margin-top: 12px;
      margin-bottom: 22px;
    }
    .currency-selector-wrapper {
      display: inline-block;
      &::after {
        color: $js-cat-nav-mobile-lv1-link-color;
      }
      select {
        background-color: $js-cat-nav-mobile-currency-background-color;
        color: $js-cat-nav-mobile-lv1-link-color;
        border: 1px solid $js-cat-nav-mobile-currency-border-color;
      }
    }
    .culture-selector-wrapper {
      /*display: inline-block;*/
      display: none;
      &::after {
        color: $js-cat-nav-mobile-lv1-link-color;
      }
      select {
        background-color: $js-cat-nav-mobile-culture-background-color;
        color: $js-cat-nav-mobile-lv1-link-color;
        border: 1px solid $js-cat-nav-mobile-culture-border-color;
      }
    }
    #category-navigation + .culture-selector-wrapper {
      margin-top: 12px;
    }
    #category-navigation + .currency-selector-wrapper {
      margin-top: 12px;
    }
    #category-navigation + .vat-selector-wrapper {
      margin-top: 12px;
    }

  }

}

//
//  Large up
//
@media #{$large-up} {
  .nav-bar {
    #pages-list-placeholder {
      display: none;
    }
    .vat-selector-wrapper {
      display: none;
    }
    .currency-selector-wrapper {
      display: none;
    }
    .culture-selector-wrapper {
      display: none;
    }
  }
}


//
// Custom pages list in mobile cat nav
//
#cat-nav #pages-list-placeholder {
  margin-top: 16px;
  margin-bottom: 8px;

  ul#list-pages {
    li {
      background: $js-cat-nav-mobile-lv1-item-background-color;
      padding: $js-cat-nav-mobile-lv1-item-padding;
      margin: $js-cat-nav-mobile-lv1-item-margin;
      border-bottom: $js-cat-nav-mobile-lv1-item-separator-border;
      border-right: $js-cat-nav-mobile-lv1-item-border-right;
      border-left: $js-cat-nav-mobile-lv1-item-border-left;
      display: block;
      line-height: 0;
      position: relative;
      width: auto;
      float: none;
      max-width: 30rem;
      &:last-child {
        border-bottom: none;
      }
    }

    a {
      display: block;
      padding: $js-cat-nav-mobile-lv1-link-padding;
      margin: $js-cat-nav-mobile-lv1-link-margin;
      background: none !important;
      border: none;
      font-family: $js-cat-nav-mobile-lv1-link-font-family;
      font-size: $js-cat-nav-mobile-lv1-link-font-size - 1px;
      line-height: $js-cat-nav-mobile-lv1-link-line-height;
      color: $js-cat-nav-mobile-lv1-link-color !important;
      text-decoration: $js-cat-nav-mobile-lv1-link-text-decoration;
      height: auto;
      width: auto;
      float: none;

      &:hover {
        text-decoration: underline;
      }

    }
  }

}
