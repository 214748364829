@import "../../../scss/foundation/functions";
@import "../../../scss/base_settings";
@import "../../../scss/responsive-base/mixins";


table.relatedTable {
  display: none;
}

.tab-related ul.product-list{
  font-size: 0;
  li{
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0 1% 20px 0;
    background: #fff;

    .product-image {
      margin: 0 0 10px;
      overflow: hidden;
      position: relative;
      width: 100%;
      padding-top: 140%;
      a{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        img{
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          margin: auto;
        }
      }
    }
    .product-info{
      text-align: center;
      .product-name {
        clear: none;
        margin: 0 0 2px 0;
        word-wrap: break-word;
        display: inline-block;
        padding: 0 2px;
        h3 {
          margin-bottom: 2px;
          font-size: 18px;
          font-weight: 700;
          text-transform: none;
          padding: 0 6px 0 0;
          font-style: normal;
          a {
            color: #333333;
            margin: 0;
            height: auto;
            overflow: hidden;
            font-family: "Josefin Sans", sans-serif;
          }
        }
      }
      .product-price {
        display: inline-block;
        padding: 0 2px;
        .price-amount {
          font-size: 18px;
          font-family: "Josefin Sans", sans-serif;
          font-weight: 400;
          text-transform: lowercase;
        }
      }
    }
  }
}


//
//    MEDIUM DOWN
//
@media #{$medium-down} {
  .tab-related ul.product-list li .product-image a img {
    max-width: 400px;
    max-height: 550px;
  }
}


//
//  Small
//
@media #{$small-only} {
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#tab-wrapper .tab-related .product-list li");
}

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width(3, $js-product-wrapper-margin-right, "#tab-wrapper .tab-related .product-list li");
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, "#tab-wrapper .tab-related .product-list li");
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width(6, $js-product-wrapper-margin-right-large-up, "#tab-wrapper .tab-related .product-list li");
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width(6, $js-product-wrapper-margin-right-large-up, "#tab-wrapper .tab-related .product-list li");
}
