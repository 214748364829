//
//  Start Page
//

.page-default {
  #path-nav {
    display: none;
  }
  #main-area .content {
    margin: -10px 0 0 0;
  }
}

.page-default .startpage-image {
  text-align: center;
  margin-bottom: 0px;
}

.page-default .startpage-text {
  margin-bottom: 0px;
}

.page-default {
  #main-area {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 0px;
  }
}

//
//  Hide the div that holds the HTML-definitions for sliders
//
#switcher-definitions {
  display: none;
}


#startpage_list {
  ul {
    li {
      position: relative;
      margin-bottom: 5px;
      .product-info {
        text-transform: uppercase;
        @include product-name-h3
      }
      .product-wrapper {
        .product-image {
          padding-top: $js-startpage-wrapper-image-wrapper-height;
        }
      }
      .custom-item-text h2 {
        text-align: center;
      }
      .custom-item-text p {
        font-family: $font-family-serif;
      }

    }
  }
}

span.text-on-image {
  bottom: 10px;
  color: #fff;
  display: block;
  left: 0px;
  margin: 0;
  position: absolute;
  text-shadow: 0 0 0px #000;
  text-align: center;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  padding: 10px;
}

span.text-on-image {
  color: #fff;
  text-shadow: 0 0 0px #000;
  text-align: center;
  h3 {
    font-family: $font-family-script;
    color: #fff;
    text-transform: none;
    font-size: 84px;
    font-weight: $font-weight-normal;
  }
}

/**** Startpage slider 2.0 *****/
#startpage_list .slider-wrapper {
  .slider {
    margin: 0 0px;
    text-align: center;
    .slick-prev, .slick-next {
      z-index: 2;
      width: 40px;
      height: 40px;
    }
    .slick-next {
      right: 25px
    }
    .slick-prev {
      left: 25px;
    }
    .slick-prev:before, .slick-next:before {
      font-size: 40px;
    }
    ul.slick-dots {
      bottom: 0px;
      li {
         background: transparent;
         width: auto;
         button {
           width: 30px;
           height: 30px;
           padding: 0px;
         }
         button:before {
           font-size: 20px;
           width: 30px;
           height: 30px;
           color: #fff;
           opacity: .50;
         }
      }

    }
    ul.slick-dots li.slick-active button:before {
     opacity: 1;
     color: #fff;
    }
  }

}
#startpage_list ul li .custom-item-text {
  .slide-1, .slide-2, .slide-3, .slide-4, .slide-5, .slide-6, .slide-7, .slide-8 {
    height: 700px;
    overflow: hidden;
    background: no-repeat center center / cover;
    h2 {
      color: #fff;
      font-size: 50px;
      padding: 270px 0 0 0;
      margin: 0;
      text-shadow: 0px 0px 15px #444;
      font-weight: $font-weight-normal;
    }
    h3 {
      color: #fff;
      font-size: 100px;
      padding: 270px 0 0 0;
      margin: 0;
      text-shadow: 0px 0px 8px #444;
      font-family: "AdornPomander", serif;
      text-transform: none;
      font-weight: $font-weight-normal;
      line-height: 1em;
    }
    p {
      color: #fff;
      font-size: 20px;
      font-weight: $font-weight-normal;
      text-shadow: 0px 0px 8px #444;
      letter-spacing: 1px;
      font-family: $font-family-sans-serif;
    }

  }
  .slide-1 {
    background-image: url("/pub_docs/files/slider/slide-1.jpg");
  }
  .slide-2 {
    background-image: url("/pub_docs/files/slider/slide-2.jpg");
  }
  .slide-3 {
    background-image: url("/pub_docs/files/slider/slide-3.jpg");
  }
  .slide-4 {
    background-image: url("/pub_docs/files/slider/slide-4.jpg");
  }
  .slide-5 {
    background-image: url("/pub_docs/files/slider/slide-5.jpg");
  }
  .slide-6 {
    background-image: url("/pub_docs/files/slider/slide-6.jpg");
  }
  .slide-7 {
    background-image: url("/pub_docs/files/slider/slide-7.jpg");
  }
  .slide-8 {
    background-image: url("/pub_docs/files/slider/slide-8.jpg");
  }
  p.slide-btn {
     font-size: 13px;
     width: auto;
     margin: 0 auto;
     background: #111;
     padding: 4px 30px;
     text-shadow: 0px 0px 0px #444;
     display: inline-block;
     letter-spacing: 1px;
     font-weight: $font-weight-normal;
     font-family: $font-family-sans-serif;
    color: #fff;
   }
  .slider a:hover {
    text-decoration: none;
  }
}


// Banneryta
.banner-yta {
  background: #aaa;
  padding: 50px 120px;
  text-align: center;
  font-size: 1.6rem;
  color: #fff;
  font-family: $font-family-serif;
  line-height: 1.2em;
}

// Slider newsletter

.slider-newsletter-wrapper {
  border: 3px solid rgba(214,101,23,0.8);
  background: transparent;
  width: 450px;
  margin: 120px auto 0;
  display: block;
  padding: 3px;
  text-align: center;
  .slider-newsletter {
    background: rgba(214, 101, 23, 0.7);
    padding: 80px 30px;
    h4 {
      font-family: $font-family-serif;
      font-size: 36px;
      padding: 0;
      color: #fff;
      font-weight: $font-weight-normal;
      margin: 0 0 30px 0;
      font-style: italic;
      text-transform: none;
    }
  }
}


//
//  Small
//
@media #{$small-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-1 li");
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-2 li");
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-3 li");
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-4 li");

  /**** Startpage slider 2.0 *****/

  #startpage_list ul li .custom-item-text {
    .slide-1, .slide-2, .slide-3, .slide-4, .slide-5, .slide-6, .slide-7, .slide-8 {
      padding: 0 60px 0;
      height: 350px;
      h2 {
        font-size: 1.6rem;
        padding: 120px 0 0 0;
      }
      h3 {
        font-size: 3rem;
        padding: 100px 0 0 0;
        line-height: 1em;
      }
      p {
        font-size: 16px;
      }

    }
    .slider-wrapper .slider {
      margin: 0px 0px 0;
    }
    p.slide-btn {
      font-size: 13px;
      padding: 4px 20px;
    }
    .slider-wrapper .slider {
      .slick-next {
        right: 10px
      }
      .slick-prev {
        left: 10px;
      }
      .slick-prev:before, .slick-next:before {
        font-size: 20px;
      }
    }
  }

  span.text-on-image {
    h3 {
      font-size: 3rem;
    }
  }

// Banneryta
  .banner-yta {
    background: #aaa;
    padding: 50px 40px;
    font-size: 1.2rem;
  }

// Slider newsletter

  .slider-newsletter-wrapper {
    width: 80%;
    margin: 100px auto 0;
    .slider-newsletter {
      padding: 30px 10px;
      h4 {
        font-size: 21px;
      }
    }
  }
}



//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right, "#startpage_list ul.items-3 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 2)
  }

  /**** Startpage slider 2.0 *****/
  #startpage_list ul li .custom-item-text {
    .slide-1, .slide-2, .slide-3, .slide-4, .slide-5, .slide-6, .slide-7, .slide-8 {
      padding: 0 60px 0;
      height: 500px;
      h2 {
        font-size: 2rem;
        padding: 140px 0 0 0;
      }
      h3 {
        font-size: 4rem;
        padding: 140px 0 0 0;
      }
      p {
        font-size: 16px;
      }

    }
    p.slide-btn {
      font-size: 13px;
      padding: 4px 20px;
    }
    .slider-wrapper .slider {
      .slick-next {
        right: 15px
      }
      .slick-prev {
        left: 15px;
      }
      .slick-prev:before, .slick-next:before {
        font-size: 30px;
      }
    }
  }

  span.text-on-image {
    h3 {
      font-size: 3rem;
    }
  }

// Banneryta
  .banner-yta {
    padding: 50px 80px;
  }

// Slider newsletter

  .slider-newsletter-wrapper {
    width: 450px;
    margin: 100px auto 0;
    .slider-newsletter {
      padding: 40px 30px;
      h4 {
        font-size: 36px;
      }
    }
  }
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-3 li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 4)
  }
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-3 li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 5);
  }
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-3 li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 5);
  }
  #startpage_list .items-2 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 1.4);
  }
}

