//
//  Header
//

#header {
  clear: both;
  position: relative;
  width: 100%;
  margin: 0;
  background-color: $js-header-background-color;
  @include clearfix();
}

#header-inner {
  max-width: $js-max-page-width;
  margin: 0 auto;
  @include clearfix();
}

.border-top {
  background: $primary-color;
  overflow: hidden;
  .border-top-inner {
    max-width: $js-max-page-width;
    margin: 0 auto;
    @include clearfix();
    ul, ol {
      text-align: center;
      margin: 0px;
      li {
        display: inline-block;
        color: #fff;
        padding: 0 15px 0 0;
        line-height: 24px;
        font-size: 13px;
        font-weight: $font-weight-normal;
        font-family: $font-family-sans-serif;
        &:before {
          font-family: FontAwesome;
          content: "\f192";
          padding: 0 10px 0 0px;
          font-size: 8px;
          margin: 0px 0 0 0;
          display: block;
          float: left;
        }
        a {
          color: #fff;

        }
      }
    }
  }
}

.logo-wrapper {
  float: left;
  margin: $js-logo-wrapper-margin;

  .logo-header {
    display: none;
  }

  .logo-body {
    max-width: $js-logo-wrapper-max-width;
    line-height: 0;
  }

  input {
    outline: none;
    max-width: 100%;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
  }

  .logo-footer {
    display: none;
  }
}

.header-scrolled .logo-wrapper {
}

//
//  Sub Navigation / Breadcrumbs
//
#path-nav {
  display: block;
  left: 0;
  width: 100%;
  max-width: $js-max-page-width;
  padding-left: $js-main-area-padding-left;
  padding-right: $js-main-area-padding-right;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: $js-breadcrumb-font-size;
  margin: 0 auto;
  background: #fff;

  .breadcrumb-link {
    color: $js-breadcrumb-font-color;
    display: inline-block;
    text-transform: uppercase;
    &:after {
      font-family: FontAwesome;
      content: "\f192";
      padding: 0 10px 0 10px;
      font-size: 8px;
      margin: 0px 0 0 0;
      display: block;
      float: right;
      line-height: 26px;
    }
  }
  a:last-child {
    color: $secondary-color;
    &:after {
      display: none;
    }
  }

  .breadcrumb-spacer {
    color: $js-primary-link-color;
    display: none;
  }
}

//
//  Search Box
//

.search-box-wrapper {
  font-size: 14px;
  padding: $js-search-box-wrapper-padding;
  margin: $js-search-box-wrapper-margin;
  float: right;
  background-color: $js-search-box-wrapper-background-color;
}

.search-box-header {
  display: none;
}

.search-box-body {
  position: relative;
  overflow: hidden;
  height: auto;
  line-height: 32px;
}

.search-box-input {
  float: left;
}

.search-box-input input {
  border: 0px solid $primary-color;
  width: $js-search-box-input-width;
  background-color: $js-search-box-input-background-color;
  padding: $js-search-box-input-padding;
  font-size: $js-search-box-input-font-size;
  outline: none;
  color: $js-search-box-input-color;
  @include border-radius(0);
  -webkit-appearance: none;
  display: block;
}

.search-box-input input:focus {
  background: $js-search-box-input-background-color-focus;
  color: $js-body-font-color;
}

.search-box-button {
  float: left;
  margin-top: -4px;
  position: absolute;
  right: 6px;
  a {
    padding: 0;
    &:focus {
      outline: none;
    }
  }
  img {
    width: 21px;
  }
}

/* MARQUEE
----------------------------------------------------------------------------- */
.marquee {
  margin: 0;
  display: none;
}

.sv-text, .en-text {
  display: none;
}

.newsletter-link {
  position: absolute;
  top: 60px;
  right: 340px;
}
a.nyhetsbrev {
   color: $secondary-color !important;
   text-transform: uppercase;
   font-size: 14px;
   font-family: $font-family-sans-serif;
 }


//
// LARGE UP
//

@media #{$large-up} {
  #header-inner {
    padding: 0 15px;
  }

}

//
//    MEDIUM DOWN
//
@media #{$medium-down} {
  .vat-selector-wrapper .vat-selector-label span {
    font-size: 1em;
  }
  .logo-wrapper {
    margin: $js-logo-wrapper-margin-medium;
  }
  .newsletter-link {
    position: absolute;
    top: 60px;
    right: 10px;
  }

}


//
//  Small
//
@media #{$small-only} {
  .logo-wrapper {
    float: none;
    display: inline-block;
    width: 100%;
    margin: $js-logo-wrapper-margin-small;
    .logo-body {
      margin: 0 auto;
    }
  }
  #path-nav {
    padding-left: 20px;
    padding-right: 20px;
    .breadcrumb-link {
      font-size: 12px;
      &:after {
        line-height: 20px;
      }
    }
  }
}