.product-attributes > div {
  display: block;
}

.product-attributes > div > span {
  padding: 0;
}


.product-attributes .color {

  > span:first-child {
    display: none;
  }

  .button {
    font-size: 0;
  }

}

.product-attributes .color .button {
  display: inline-block;
  transition: 0.1s;
  font-weight: normal;
  font-size: 12px;
  width: 22px;
  height: 22px;
  border-radius: 30px;
  border: 0px solid #fff;
  margin: 0 5px 5px 5px;
  padding: 2px;
  background: #eee;

  &.white,
  &.offwhite {
    border: 1px solid #ccc;
    background: #eee;

    .inner {
      border: 0px solid #ccc;
      background: #eee;
    }
  }

  .inner {
    padding: 0px;
    border: 0px solid #333;
    height: 18px;
    width: 18px;
    border-radius: 30px;
    background: #eee;
  }

  &.selected {

    .inner {
      border: 2px solid #fff;
    }

    &.white,
    &.offwhite {

      .inner {
        border: 2px solid #ccc;
        height: 16px;
        width: 16px;
      }
    }
  }

  .name {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 13px;
    padding: .5rem;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    color: #333;
    text-transform: none;
    font-weight: $font-weight-medium;
  }

  &:hover .name {
    opacity: 1;
    visibility: visible;
  }
}

.product-attributes #sub-attr-buttons .button {
  display: inline-block;
  color: #666;
  font-weight: normal;
  background-color: #f7f7f7;
  border: 2px solid #fff;
  padding: 0;
  margin: 0 2px 5px 5px;
  border-radius: 40px;
  height: 34px;
  width: 34px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;

  .inner {

  }

  &.selected {

    border: 2px solid #d3d3d3;

  }
}


.product-attributes .button:hover {
  background-color: #1f3779;
}

.product-attributes .button.not-buyable {
  background: #ccc !important;
  color: #555;
  border-color: #999;
  outline: none !important;
}

.product-attributes .button.not-buyable:hover {
  background-color: #ddd !important;
}


.product-attributes .button.selected.not-buyable {
  background: #aaa !important;
  border-color: #333;
  color: #000;
}

.product-attributes .sub-attr-section {
  display: none;
}

.product-attributes .sub-attr-section.default {
  display: block;
  //opacity: 0.5;
}

.product-attributes .sub-attr-section.active {
  display: block;
  opacity: 1;
}

.ProductCommentValidatorErrorCssClass {
  color: #c60f12;
  background-color: #fcd8d9;
  padding: 5px;
}

.ProductCommentValidatorErrorCssClass:before {
  font-family: "FontAwesome";
  content: "\f071";
  padding-right: 6px;
}

.product-attributes > div > span:first-child {
  font-weight: bold;
  padding: 10px 5px 0px 0;
}

.product-attributes .single-sub-attr > span:first-child {
  //display: inline-block;
}

.product-attributes > div > span:first-child::after {
  content: ":";
}

.single-sub-attr #sub-attr-buttons {
  display: inline-block;
}

.single-sub-attr #sub-attr-buttons .sub-attr {
  display: inline-block;
  border: none;
  background: none !important;
  padding: 0;
  margin: 0;
  color: #222;
  font-weight: normal;
  font-size: 16px;
  box-shadow: none;
}

.product-attributes select {
  display: none;
}

.purchase-block-body {
  padding-top: 10px;
}



/* SMALL */
@media screen and (max-width: 767px) {

  html .product-attributes * {
    width: auto !important;
  }

  .product-attributes .button {
    margin: 0 10px 10px 0;
    padding: 10px 7px;
  }
}