
/* PRODUCT PAGE
----------------------------------------------------------------------------- */

#stock-notification-popup {
  height: auto;

  * {
    box-sizing: border-box;
  }
}

.page-showproduct {
  .fancybox-iframe {
    html, body {
      height: 100%;
    }
  }
}


.product-images {
  float: left;
  width: 50%;
  margin: 0 0 10px 0;
  padding-right: 30px;
}

.product-description {
  float: right;
  width: 50%;
  margin: 0 0 10px 0;
  clear: right;
}

#FrontImage {
  max-width: 100%;
  cursor: pointer;
  vertical-align: bottom;
}

.show-product-image {
  position: relative;
}

a.ProductThumbnail {
//  overflow: hidden;
//  width: 20%;
//  float: left;
}

a.ProductThumbnail img {
//  margin: 10px;
}

.ProductThumbnailsBody {
  margin-top: 20px;
}
.ProductImageClickMessage {
  display: none;
}

h1.product-page-header {
  margin: 0 0 10px 0;
  font-size: 38px;
}

h2.product-subname {
  margin: 0 0 10px 0;
  color: $js-product-list-subname-color;
}

h1.product-page-header,
h2.product-subname {
  word-wrap: break-word;
  font-family: $font-family-sans-serif;
}

.product-short-description {
  @include js-description-text($js-description-font-size);
  font-family: $font-family-sans-serif;
}

.tab-item.tab-description {
  @include js-description-text($js-description-font-size);
  font-family: $font-family-sans-serif;
}


.product-short-description, .product-description .inner {
  overflow: hidden;
  margin: 0 0 5px 0;
}

.product-short-description {
  margin-bottom: 22px;
}

.product-description .inner div {
  margin: 0 0 5px 0;
}

span.product-article-number-label, span.product-unit-label, span.stock-status-label {
  font-weight: bold;
  margin: 0 5px 0 0;
}

.purchase-block-stock {
  margin: 0 0 15px;
}

// Hide article number
.product-article-number {
  display: none;
}

// Hide product unit
.product-unit {
  display: none;
}

.ProductCommentItemCssClass {
  margin: 0 0 20px 0;
}

.ProductCommentItemCssClass span {
  display: block;
  font-weight: bold;
}

.ProductCommentLargeInputCssClass {
  width: 100%;
  padding: 4px;
  border: 1px solid #cdcdcd;
  resize: vertical;
}


.product-specification-params {
  display: none;
}

/**** Attribut ***/

html body {
  .attribute-link .attribute-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: .8rem;
    padding: .5rem;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    color: #333;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
  }
  .attribute-link:hover:not(.inStock) .attribute-overlay {
    opacity: 1;
    visibility: visible;
  }
}

.product-attributes {
  overflow: hidden;
}

.product-attributes span {
  display: block;
  margin: 0 0 3px 0;
}

.attribute-link.inactive {
  cursor: not-allowed;
}

.product-attributes-1 {
  margin: 0 20px 0 0;
  font-weight: bold;
  position: relative;
  padding: 35px 0 0;
  #ctl00_main_ctl00_ctl00_labAttribute1 {
    display: none;
  }
  span {
    display: block;
    padding-bottom: 11px;
  }
  .attribute-link {
    width: 22px;
    height: 22px;
    border-radius: 30px;
    border: 0px solid #fff;
    margin: 0 5px 5px 5px;
    padding: 2px;
    background: #eee;
    span.attribute-link-inner {
      padding: 0px;
      border: 0px solid #333;
      height: 18px;
      width: 18px;
      border-radius: 30px;
      background: #eee;
    }
  }
  .attribute-link.white,
  .attribute-link.offwhite{
    border: 1px solid #ccc;
    background: #eee;
    span.attribute-link-inner {
      border: 0px solid #ccc;
      background: #eee;
    }
  }
  a {
    line-height: 0;
    font-size: 0;
    color: transparent;
  }
  .attribute-link-active span.attribute-link-inner {
    border: 2px solid #fff;
  }
  .attribute-link-active.white span.attribute-link-inner, .attribute-link-active.offwhite span.attribute-link-inner {
    border: 2px solid #ccc;
    height: 16px;
    width: 16px;
  }
}


.attribute-link {
  display: inline-block;
}

.product-attributes-2 {
  margin: 12px 0 10px 0;
  font-weight: bold;
  .attribute-link {
    color: #666;
    font-weight: normal;
    background-color: #f7f7f7;
    border: 2px solid #fff;
    padding: 0;
    margin: 0 2px 5px 5px;
    border-radius: 40px;
    height: 34px;
    width: 34px;
    line-height: 30px;
    text-align: center;
  }
  .attribute-link-active {
    border: 2px solid #d3d3d3;
  }
  a {
    line-height: 10px;
  }
  span#ctl00_main_ctl00_ctl00_rfvAttr2 {
    visibility: hidden !important;
  }
}

.product-attributes-params {
  display: none;
}




span.product-specification-label {
  font-weight: bold;
  display: block;
  margin: 0 0 2px 0;
}

.product-specification-wrapper {
  margin-bottom: 24px;
}

.product-specification-wrapper select {
  min-width: 190px;
  margin: 0 0 10px 0;
}

.purchase-block {
  clear: both;
  overflow: hidden;
}

.page-showproduct .purchase-block-price {
  margin-bottom: 10px;
  .currency {
    font-size: 0.6em;
    padding: 0 0 0 5px;
    text-transform: lowercase;
  }
  .price {
    font-weight: 300;
    text-transform: lowercase;
  }
}

.page-showproduct .purchase-block-price-currency {
  padding: 0 0 0 5px;
}

.page-showproduct .purchase-block-price-unit {
  display: none;
}

.purchase-block-price .KlarnaMonthlyCost {
  margin: 5px 0 0 0;
}

.purchase-block-quantity {
  float: left;
  margin: 0 10px 0 0;
}

.purchase-block-quantity span {
  display: none;
}

.purchase-block-quantity input {
  width: 44px;
  height: 41px;
  padding: 0;
  text-align: center;
  border: 2px solid $primary-color;
}

.desc-accordian {
  display: block;
  clear: both;
  margin: 15px 0 0 0;
  .tab-item {
    clear: both;
    h2.tab-item-header {
      border:0px;
      font-size:16px;
      cursor: pointer;
      font-style: normal;
      font-family: $font-family-sans-serif;
      &:before {
        content: "\f067";
        font-size: 14px;
        font-weight: 300;
        padding: 0 10px 0 0;
        font-family: FontAwesome;
      }
    }
    h2.tab-item-header.open {
      &:before {
        content: "\f068";
        font-size: 14px;
      }
    }
    .tab-item-inner {
      display: none;
      p {
       font-family: $font-family-sans-serif;
      }
    }
  }
}

/* TEMPLATES */
.template-wrapper .templateRow,
.template-wrapper .templateAlternateRow
{
  border-bottom: 1px solid #dfdfdf;
  padding: 10px 10px 10px 0;
}


.templateItemTitle {
  display: inline-block;
  width: 150px;
  font-weight: bold;
  vertical-align: top;
}

.templateItemData {
  display: inline-block;
  width: 150px;
  vertical-align: top;
}

/* RELATED PRODUCTS */
table.relatedTable tr {
  border-bottom: 1px solid #dfdfdf;
  display: inline-block;
}

table.relatedTable tr:first-child, table.relatedTable tr:last-child {
  border-bottom: 0;
}

table.relatedTable tr.headerGray {
  display: none;
}

table.relatedTable td {
  vertical-align: middle;
  padding: 10px 10px 10px 0;
}

td.RelatedProductImage {
  width: 30%;
  text-align: left !important;
}

td.RelatedProductName {
  width: 30%;
}

td.RelatedProductName a {
  font-weight: bold;
}

td.RelatedProductArticleNumber {
  display: none;
}

td.RelatedProductPrice {
  width: 20%;
}

td.RelatedProductButtons {
  width: 20%;
}

td.RelatedProductButtons a {
  float: right;
}

td.RelatedProductButtons br {
  display: none;
}

/* TOOLBAR */
.product-toolbar {
  @include clearfix;
  clear: both;
  display: inline-block;
  margin: 0 10px 10px 0;
  //padding: 10px;
  //background: #efefef;
  //border-top: 1px solid #cdcdcd;
  //behavior: url(/SystemScripts/PIE.htc);
}

.product-toolbar img {
  width: 75%;
}

.product-toolbar-image-wrapper {
  float: left;
  margin: 0 15px 0 0;
  cursor: pointer;
}

.product-toolbar-image-wrapper.product-toolbar-print,
.product-toolbar-image-wrapper.product-toolbar-showimage,
.product-toolbar-image-wrapper.product-toolbar-pdf
{
  display: none;
}

.social-media {
  float: right;
}

.product-page-back-link {
  clear: both;
  float: left;
  cursor: pointer;
  margin: 10px;
  font-weight: bold;
}

/* PRODUCT FILES */
.product-files-box {
  background: white;
  border: 1px solid #D8D8D8;
  padding: 10px;
  text-align: left;
  width: 300px;
  min-width: 300px;
  border: solid 1px rgb(180, 180, 180);
}

.product-files-box-shadow {
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  opacity: 0.15;
  filter: alpha(opacity=15);
}

.product-files-link {
  line-height: 21px;
  padding-left: 5px;
}

.product-files-button {
  float: left;
}

.product-files-box-close {
  min-width: 30px;
  min-height: 30px;
  text-decoration: underline;
  cursor: pointer;
  background: url(/images/fancybox/fancy_closebox.png) no-repeat;
  height: 30px;
  width: 30px;
  float: right;
}

.product-files-header {
  font-weight: bold;
}


div.product-tabs {
  margin: 0;
}

//
//  Tab system
//

#tab-wrapper {
  clear: both;
}

.tab-item {
  margin-bottom: 15px;
  &:first-child {
    margin-top: 44px;
  }
  .tab-item-header {
    font-size: 1.4em;
    padding-bottom: 3px;
    border-bottom: 1px dotted #cdcdcd;
  }
  .tab-item-inner {
    padding-bottom: 8px;
  }
}


//
//  Errors on product comment validation
//
.ProductCommentValidatorErrorCssClass {
  color: $alert-color;
  background-color: lighten($alert-color, 50%);
  padding: 5px;
  &:before {
    font-family: "FontAwesome";
    content: "\f071";
    padding-right: 6px;
  }
}


//
//  Small
//
@media #{$small-only} {


  .product-attributes-1 {

    .attribute-link {
      width: 26px;
      height: 26px;
      span.attribute-link-inner {
        height: 22px;
        width: 22px;
      }
    }
    .attribute-link-active.white span.attribute-link-inner, .attribute-link-active.offwhite span.attribute-link-inner {
      height: 22px;
      width: 22px;
    }
  }
  .product-images {
    float: none;
    width: 100%;
    margin: 0;
    padding-right: 0;
  }
  .product-description {
    float: none;
    width: 100%;
    margin: 0;
    clear: both;
  }
}