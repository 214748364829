.read-more-wrapper {
  position: relative;
  overflow: hidden;
  transition: max-height 0.4s;
  .read-more-inner {
    position: relative;
  }
  &:after {
    bottom: 0;
    content: " ";
    display: block;
    height: 50px;
    width: 100%;
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 80%
    );
  }
  &.grey:after {
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, white 90%);
  }
  &.active:after {
    position: absolute;
  }
  .toggle-more {
    display: flex !important;
    position: absolute !important;
    text-align: center;
    align-items: end;
    bottom: 0;
    padding: 2px 0;
    z-index: 1;
    width: 100%;
    color: #F27428;
    font-family: "Josefin Sans", sans-serif;
    display: flex;
    flex: auto;
    justify-content: center;

    &:after {
      content: '\f078';
      font-family: FontAwesome;
      font-weight: normal;
      font-size: 18px;
      margin-left: 5px;
      color: #F27428;
    }
    &:hover {
      color: #333;
    }
  }
  &.open {
    overflow: visible;
    padding-bottom: 30px;
    &:after {
      display: none;
    }
    .toggle-more {
      bottom: 0px;
      &:after {
        content: '\f077';
        font-family: FontAwesome;
      }
    }
  }
}


//
//  Small
//
@media #{$small-only} {
  .category-header-subtitle {
    line-height: 1.64em;
  }
}
