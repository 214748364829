//
//  Standard pages
//
.PageDetailsWrapper {
  clear: both;
  overflow: hidden;
  margin: 0 auto;
  max-width: $js-max-page-width;

  .PageDetailsImage {
    float: right;
    max-width: 50%;
    margin: 0 0 0 20px;

    img {
      margin: 0 0 20px;
    }
  }
}

//
//  Small
//
@media #{$small-only} {
  .PageDetailsWrapper {
    .PageDetailsImage {
      float: none;
      max-width: none;
      margin: 0;

      img {
        margin: 0 0 15px;
      }
    }
  }
}